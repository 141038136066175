



























































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { breadcrumb } from "@/utils/breadcrumb";
import {
  ApiGetControlHistoryList,
  ApiExportControlHistory,
  ApiGetImpedanceHistoryList,
  ApiExportImpedanceHistory,
  ApiExportControlHistoryFirst,
  ApiExportImpedanceHistoryFirst,
} from "@/apis/index";
@Component({
  name: "programControlHistoryIndex",
})
export default class ProgramControlHistory extends Vue {
  //data
  condition: any = {
    user_name: "",
    date_start: "",
    date_end: "",
    model: "",
  };
  modelList = [
    { id: 1, name: "G101" },
    { id: 2, name: "G102R" },
    { id: 3, name: "G102" },
    { id: 4, name: "G111" },
    { id: 5, name: "G101A" },
    { id: 6, name: "G101T" },
    { id: 7, name: "G102T" },
    { id: 8, name: "G102RS" },
    { id: 9, name: "G131" },
    { id: 10, name: "G132" },
    { id: 11, name: "G112" },
    { id: 12, name: "G101ANEW" },
    { id: 13, name: "G102NEW" },
    { id: 14, name: "G102RNEW" },
    { id: 15, name: "G114R" },
    { id: 17, name: "G103" },
    { id: 18, name: "G104" },
    { id: 19, name: "G104R" },
    { id: 20, name: "G105" },
    { id: 21, name: "G106" },
    { id: 22, name: "G106R" },
    { id: 23, name: "G102RZ" },
    { id: 25, name: "T902" },
    { id: 26, name: "G122" },
    { id: 27, name: "G122R" },
    { id: 28, name: "G134R" },
    { id: 29, name: "G106RS" },
    { id: 30, name: "G113R" },
    { id: 31, name: "G106RST" },
    { id: 32, name: "G107R" },
    { id: 33, name: "G107" },
  ];
  tableData: Array<any> = [];
  pagination = {
    //当前是第几页
    page: 1,
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
  };
  activeName = "first";
  tabIndex = 0;
  clickTab(tab: { index: string }, event: object) {
    const index = tab.index;
    switch (index) {
      case "0": {
        this.tabIndex = 0;
        break;
      }
      case "1": {
        this.tabIndex = 1;
        break;
      }
    }
    this.resetCondition();
  }
  //hook
  mounted() {
    this.init();
  }
  //methods
  init() {
    this.updateTableData(this.tabIndex);
  }
  databaseIdClick(id: number | string) {
    localStorage.setItem("tabIndex", "5");
    localStorage.setItem("activeName", "sixth");

    // this.$router.push("/doctor-system/medical/view?user_id=" + id);
    breadcrumb.push(
      this,
      "/doctor-system/medical/view",
      "患者详情",
      {
        user_id: id,
      },
      false
    );
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  dash(data: string | number | undefined | null) {
    let result: string | number = "-";
    if (data || data === 0) {
      result = data;
    }
    return result;
  }
  //当表格为空时，表格默认显示内容
  emptyContent = "暂无数据";
  //获取列表数据
  updateTableData(index: number) {
    this.emptyContent = "暂无数据";
    let endTime = this.condition.date_end;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      endTime = endTime + timeLimit - 1;
    }
    const param = {
      user_name: this.condition.user_name.trim(),
      date_start: this.condition.date_start,
      model: this.condition.model,
      date_end: endTime,
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    this.isExporting = true;
    //参数历史查询列表
    if (index === 0) {
      ApiGetControlHistoryList(param).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          this.tableData.forEach((item) => {
            item.summaryFormat = item.summry ? item.summry.split("\n") : "-";
          });
          this.pagination.total = res.page.total_size;
        } else {
          console.log("err:", res);
          this.emptyContent =
            "抱歉，暂时没有找到相关数据，请您联系品驰工作人员";
        }
        this.isExporting = false;
      });
      //阻抗历史查询列表
    } else {
      ApiGetImpedanceHistoryList(param).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          this.pagination.total = res.page.total_size;
        } else {
          console.log("err:", res);
          this.emptyContent =
            "抱歉，暂时没有找到相关数据，请您联系品驰工作人员";
        }
        this.isExporting = false;
      });
    }
  }
  //点击查询按钮触发的事件
  clickSearch() {
    this.pagination.page = 1;
    this.pagination.size = 10;
    this.updateTableData(this.tabIndex);
  }
  //点击重置按钮触发的事件
  resetCondition() {
    //清空查询条件
    Object.keys(this.condition).map((item: any) => {
      this.condition[item] = "";
    });
    //重置分页器
    this.pagination.page = 1;
    this.pagination.size = 10;
    this.pagination.total = 0;
    //重置表格数据
    this.updateTableData(this.tabIndex);
  }
  getDate() {
    const time = new Date();
    return `${time.getFullYear()}${this.format(
      time.getMonth() + 1
    )}${this.format(time.getDate())}`;
  }
  //是否正在导出
  isExporting = false;
  //点击导出按钮触发的事件
  async extract() {
    this.isExporting = true;
    const endTime = this.condition.date_end;
    if (endTime) {
      const timeLimit = 24 * 60 * 60 * 1000;
      this.condition.date_end = endTime + timeLimit - 1;
    }
    const param = {
      ...this.condition,
      cur_page: this.pagination.page,
      page_size: this.pagination.size,
    };
    //参数历史导出
    if (this.tabIndex === 0) {
      ApiExportControlHistoryFirst(param).then((res: any) => {
        if (typeof res.success == "undefined") {
          ApiExportControlHistory(param).then((res) => {
            this.isExporting = false;
            console.log("export:", res);
            const url = URL.createObjectURL(res);
            const a = document.createElement("a");
            a.download = this.getDate() + "参数历史.xlsx";
            document.body.appendChild(a);
            a.href = url;
            a.click();
            a.remove();
          });
        } else {
          this.isExporting = false;
          this.$message.warning(res.data.message);
        }
      });

      //阻抗历史导出
    } else {
      ApiExportImpedanceHistoryFirst(param).then((res: any) => {
        if (typeof res.success == "undefined") {
          ApiExportImpedanceHistory(param).then((res) => {
            this.isExporting = false;
            console.log("export:", res);
            const url = URL.createObjectURL(res);
            const a = document.createElement("a");
            a.download = this.getDate() + "阻抗历史.xlsx";
            document.body.appendChild(a);
            a.href = url;
            a.click();
            a.remove();
          });
        } else {
          this.isExporting = false;
          this.$message.warning(res.data.message);
        }
      });
    }
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: number) {
    this.pagination.size = val;
    this.updateTableData(this.tabIndex);
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: number) {
    this.pagination.page = val;
    this.updateTableData(this.tabIndex);
  }
  //确认对话框是否显示
  showConfirmDialog = false;
  //关闭确认对话框函数
  closeConfirmDialog() {
    this.showConfirmDialog = false;
  }
  //确认对话框确定按钮点击触发
  completeConfirmConfirm() {
    this.extract();
    this.closeConfirmDialog();
  }
}
